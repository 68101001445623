import { Root } from './Root';
import { RouteObject } from 'react-router-dom';
import { injectApiReduxStore } from 'AppProvider/store';
import { lazy } from 'react';

// Anything with popover cannot be lazy loaded due to improper closing
import Profile from './Profile';
import TopUp from './TopUp';

const Home = lazy(() => import('./Home'));

const Lobby = lazy(() =>
	import('./Lobby').then(async (module) => {
		await injectApiReduxStore('Lobby');
		return module;
	}),
);

const MiniGame = lazy(() => import('./MiniGame'));
const MiniGameRoot = lazy(() => import('./MiniGame/Root'));
const MiniGameConnectN = lazy(() => import('./MiniGame/ConnectN'));

export const ROUTE_CONFIG: RouteObject[] = [
	{
		path: '/',
		element: <Root />,
		handle: {
			crumb: { label: 'Home', href: '/' },
		},
		children: [
			{
				path: '/',
				element: <Home />,
			},
			{
				path: '/top-up/*',
				element: <TopUp />,
				handle: {
					crumb: { label: 'Top Up', href: '/top-up' },
				},
			},
			{
				path: '/profile',
				element: <Profile />,
				handle: {
					crumb: { label: 'Profile', href: '/profile' },
				},
			},
			{
				path: 'mini-game',
				element: <MiniGameRoot />,
				handle: {
					crumb: { label: 'Mini Game', href: '/mini-game' },
				},
				children: [
					{
						path: '/mini-game',
						element: <MiniGame />,
					},
					{
						path: 'connect-n',
						element: <MiniGameConnectN />,
						handle: {
							crumb: { label: 'Connect N', href: '/connect-n' },
						},
					},
				],
			},
			{
				path: 'lobby',
				element: <Lobby />,
				handle: {
					crumb: { label: 'Lobby', href: '/lobby' },
				},
				children: [],
			},
		],
	},
];
