import { Button, Grid, TextField, Typography } from '@mui/material';
import { ReceiptChip } from 'Component/ReceiptChip';
import { useAccount } from 'Api/System';
import { useEffect, useState } from 'react';
import { useRenameAccount } from 'Api/System/useRenameAccount';
import { useWallet } from 'Api/Wallet';

export default function Profile() {
	const { renameInfo, renameAccount, isLoading } = useRenameAccount();
	const { account } = useAccount();
	const { wallet } = useWallet();
	const [name, setName] = useState<string>(account?.name ?? '');
	const coolDownMessageExists = Boolean(renameInfo?.coolDownMessage);
	const enoughReceipts = wallet && renameInfo && wallet?.receipts > renameInfo?.renameFee;

	useEffect(() => {
		setName(account?.name ?? '');
	}, [account?.name]);

	const handleSave = () => {
		if (!isLoading) {
			renameAccount({ name });
		}
	};

	return (
		<Grid
			container
			direction='column'
			justifyContent='center'
			alignItems='center'
			height='100%'
			width='100%'
			spacing={2}
		>
			<Grid item xs={1}>
				<Typography color='red' variant='body1'>
					*After you rename, You must wait at least seven days to before you can rename again
				</Typography>
				<Typography textAlign='center' variant='body1'>
					{renameInfo?.coolDownMessage}
				</Typography>
				<Typography textAlign='center' variant='body1'>
					{renameInfo?.isFirstRename && 'First rename is free'}
				</Typography>
				<Typography color='red' textAlign='center' variant='body2'>
					{!renameInfo?.isFirstRename && !enoughReceipts && 'Not enough receipts'}
				</Typography>
			</Grid>
			<Grid item xs={1}>
				<TextField
					label='Name'
					value={name}
					onChange={(event) => setName(event.target.value)}
					disabled={isLoading || coolDownMessageExists || (!renameInfo?.isFirstRename && !enoughReceipts)}
				/>
			</Grid>
			<Grid item xs={1}>
				<Button
					disabled={
						name === account?.name ||
						isLoading ||
						coolDownMessageExists ||
						(!renameInfo?.isFirstRename && !enoughReceipts)
					}
					onClick={handleSave}
				>
					Save
				</Button>
				<ReceiptChip
					receipts={
						<Typography sx={{ ...(renameInfo?.isFirstRename && { textDecoration: 'line-through' }) }}>
							-{renameInfo?.renameFee}
						</Typography>
					}
				/>
			</Grid>
		</Grid>
	);
}
