import { emptySplitApi } from 'Api';

export interface GetAccountResults {
	account?: Account;
	message?: string;
}

export interface Account {
	id: string;
	name: string;
	email: string;
}

export interface GetRenameInfoResults {
	info?: RenameInfo;
	message?: string;
}

export interface RenameInfo {
	renameFee: number;
	coolDownMessage: string;
	isFirstRename: boolean;
}

export interface UpdateAccountResults {
	message?: string;
}

export const ACCOUNT_TAG = 'ACCOUNT_TAG';

export const api = emptySplitApi.enhanceEndpoints({ addTagTypes: [ACCOUNT_TAG] }).injectEndpoints({
	endpoints: (build) => ({
		account: build.query<GetAccountResults, void>({
			query: (args) => ({ method: 'GET', url: 'account', body: args }),
			providesTags: [ACCOUNT_TAG],
		}),
		renameAccount: build.mutation<UpdateAccountResults, Pick<Account, 'name'>>({
			query: (args) => ({ method: 'PUT', url: 'account/name', body: args }),
			invalidatesTags: [ACCOUNT_TAG],
		}),
		renameInfo: build.query<GetRenameInfoResults, void>({
			query: () => ({ method: 'GET', url: 'account/name/info' }),
			providesTags: [ACCOUNT_TAG],
		}),
	}),
	overrideExisting: false,
});

export const { useAccountQuery, useRenameAccountMutation, useRenameInfoQuery } = api;
