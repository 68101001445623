import { emptySplitApi } from 'Api';

export interface GetWalletResults {
	wallet?: Wallet;
	message?: string;
}

export interface Wallet {
	receipts: number;
	miniGameTickets: number;
}

export enum ProductTag {
	RECEIPTS_1_000 = 'RECEIPTS_1_000',
	RECEIPTS_10_000 = 'RECEIPTS_10_000',
	RECEIPTS_100_000 = 'RECEIPTS_100_000',
	RECEIPTS_1_000_000 = 'RECEIPTS_1_000_000',
	RECEIPTS_10_000_000 = 'RECEIPTS_10_000_000',
}

export interface CheckoutDetails {
	productTag: ProductTag;
}

export interface CheckoutResults {
	url: string;
}

export const WALLET_TAG = 'WALLET_TAG';

export const api = emptySplitApi.enhanceEndpoints({ addTagTypes: [WALLET_TAG] }).injectEndpoints({
	endpoints: (build) => ({
		wallet: build.query<GetWalletResults, void>({
			query: () => ({ method: 'GET', url: 'wallet' }),
			providesTags: [WALLET_TAG],
		}),
		topup: build.mutation<CheckoutResults, CheckoutDetails>({
			query: (args) => ({ method: 'POST', url: 'stripe/checkout', body: args }),
			invalidatesTags: [],
		}),
	}),
	overrideExisting: false,
});

export const { useWalletQuery, useTopupMutation } = api;
