import { useEffect } from 'react';
import { useRenameAccountMutation, useRenameInfoQuery } from './api';
import { useSnackbar } from 'notistack';

export function useRenameAccount() {
	const { data: renameInfoResults } = useRenameInfoQuery(undefined, { refetchOnMountOrArgChange: true });
	const [renameAccount, { data: updateAccountResults, isLoading }] = useRenameAccountMutation();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (updateAccountResults) {
			if (updateAccountResults?.message) {
				enqueueSnackbar(updateAccountResults?.message, { variant: 'error' });
			} else {
				enqueueSnackbar('Rename successful', { variant: 'success' });
			}
		}
	}, [updateAccountResults, enqueueSnackbar]);

	useEffect(() => {
		if (renameInfoResults?.message) {
			enqueueSnackbar(renameInfoResults?.message, { variant: 'error' });
		}
	}, [renameInfoResults?.message, enqueueSnackbar]);

	return { renameInfo: renameInfoResults?.info, renameAccount, isLoading };
}
