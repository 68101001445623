import { Provider } from 'react-redux';
import { ReactNode } from 'react';
import { SnackbarCloseButton } from 'Component';
import { SnackbarProvider } from 'notistack';
import { store } from './store';
import AuthenticatedStompSessionProvider from './AuthenticatedStompSessionProvider';

type AppProviderProps = {
	children: ReactNode;
};

export default function AppProvider(props: AppProviderProps) {
	const { children } = props;

	return (
		<Provider store={store}>
			<SnackbarProvider maxSnack={10} action={(key) => <SnackbarCloseButton snackbarKey={key} />}>
				<AuthenticatedStompSessionProvider>{children}</AuthenticatedStompSessionProvider>
			</SnackbarProvider>
		</Provider>
	);
}
