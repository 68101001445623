import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';

type SnackbarCloseButtonProps = {
	snackbarKey: SnackbarKey;
};

export function SnackbarCloseButton({ snackbarKey }: SnackbarCloseButtonProps) {
	const { closeSnackbar } = useSnackbar();

	return (
		<IconButton onClick={() => closeSnackbar(snackbarKey)}>
			<CloseIcon />
		</IconButton>
	);
}

export default SnackbarCloseButton;
