import { AttachMoney as AttachMoneyIcon, Discount as DiscountIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	Chip,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { ProductTag, useTopup } from 'Api/Wallet';
import { ReceiptIcon } from 'Component';

const PRODUCT_OPTIONS = [
	{
		productTag: ProductTag.RECEIPTS_1_000,
		productIcon: <ReceiptIcon color='inherit' />,
		label: '1,000',
		currency: '1.00',
		savings: '0%',
	},
	{
		productTag: ProductTag.RECEIPTS_10_000,
		productIcon: <ReceiptIcon color='inherit' />,
		label: '10,000',
		currency: '9.00',
		savings: '10%',
	},
	{
		productTag: ProductTag.RECEIPTS_100_000,
		productIcon: <ReceiptIcon color='inherit' />,
		label: '100,000',
		currency: '80.00',
		savings: '20%',
	},
	{
		productTag: ProductTag.RECEIPTS_1_000_000,
		productIcon: <ReceiptIcon color='inherit' />,
		label: '1,000,000',
		currency: '700.00',
		savings: '30%',
	},
	{
		productTag: ProductTag.RECEIPTS_10_000_000,
		productIcon: <ReceiptIcon color='inherit' />,
		label: '10,000,000',
		currency: '6,000.00',
		savings: '40%',
	},
];

export default function TopUp() {
	const { topup, isLoading } = useTopup();

	const handlePurchase = (productTag: ProductTag) => {
		topup({ productTag });
	};

	return (
		<Box height='92%' padding='1%'>
			<Typography textAlign='center' padding='1%' color='red'>
				*Beware, strictly <b>no refunds</b>
			</Typography>
			<TableContainer component={Paper} elevation={3} sx={{ width: '50%', margin: 'auto' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell align='center'>Options</TableCell>
							<TableCell align='center'>Price (SGD)</TableCell>
							<TableCell align='center'>Savings</TableCell>
							<TableCell align='center'></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{PRODUCT_OPTIONS.map(({ label, productIcon, currency, savings, productTag }) => (
							<TableRow key={productTag}>
								<TableCell component='th' scope='row' align='center'>
									<Chip icon={productIcon} variant='outlined' label={label} />
								</TableCell>
								<TableCell align='center'>
									<Chip
										icon={<AttachMoneyIcon color='inherit' />}
										variant='outlined'
										label={currency}
									/>
								</TableCell>
								<TableCell align='center'>
									<Chip icon={<DiscountIcon color='inherit' />} variant='outlined' label={savings} />
								</TableCell>
								<TableCell align='center'>
									<Button
										sx={{ margin: 'auto' }}
										onClick={() => handlePurchase(productTag)}
										disabled={isLoading}
									>
										Purchase
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}
