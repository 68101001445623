import { Button, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { MiniGameTicketIcon, ReceiptIcon } from 'Component';
import { useAccount, useLogout } from 'Api/System';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useWallet } from 'Api/Wallet';
import Avatar from 'react-avatar';

export default function Header() {
	const { logout } = useLogout();
	const { account } = useAccount();
	const { wallet } = useWallet();
	const navigate = useNavigate();
	const [systemMenuAnchorEl, setSystemMenuAnchorEl] = useState<null | HTMLElement>(null);
	const openSystemMenu = Boolean(systemMenuAnchorEl);

	const handleSystemButtonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
		setSystemMenuAnchorEl(event.currentTarget);
	};

	const handleSystemMenuClosed = () => {
		setSystemMenuAnchorEl(null);
	};

	const handleTopUp = () => {
		navigate('/top-up');
	};

	const handleProfile = () => {
		navigate('/profile');
		handleSystemMenuClosed();
	};

	return (
		<Grid container direction='row-reverse'>
			<Grid item marginTop='auto' marginBottom='auto' paddingLeft='1%' paddingRight='1%'>
				<Tooltip
					title={
						<Typography textAlign='center' fontSize='12px'>
							{account?.name}
						</Typography>
					}
				>
					<IconButton
						id='system-button'
						aria-controls={openSystemMenu ? 'system-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={openSystemMenu ? 'true' : undefined}
						onClick={handleSystemButtonClicked}
					>
						<Avatar name={account?.name} round size='32' />
					</IconButton>
				</Tooltip>
				<Menu
					id='system-menu'
					anchorEl={systemMenuAnchorEl}
					open={openSystemMenu}
					onClose={handleSystemMenuClosed}
					MenuListProps={{ 'aria-labelledby': 'system-button' }}
				>
					<MenuItem sx={{ pointerEvents: 'none' }}>
						<Typography>{account?.name}</Typography>
					</MenuItem>
					<Divider />
					<MenuItem onClick={handleProfile}>Profile</MenuItem>
					<MenuItem onClick={logout}>Logout</MenuItem>
				</Menu>
			</Grid>
			<Grid item marginTop='auto' marginBottom='auto'>
				<Tooltip
					title={
						<Typography textAlign='center' fontSize='12px'>
							Receipts, your main currency
							<br />
							Click to Top-up
						</Typography>
					}
				>
					<Button color='inherit' disableRipple startIcon={<ReceiptIcon />} onClick={handleTopUp}>
						<Typography textAlign='center' variant='body1'>
							{wallet?.receipts ?? '-'}
						</Typography>
					</Button>
				</Tooltip>
			</Grid>
			<Grid item marginTop='auto' marginBottom='auto'>
				<Tooltip
					title={
						<Typography textAlign='center' fontSize='12px'>
							Mini Game Tickets
						</Typography>
					}
				>
					<Button color='inherit' disableRipple startIcon={<MiniGameTicketIcon />}>
						<Typography textAlign='center' variant='body1'>
							{wallet?.miniGameTickets ?? '-'}
						</Typography>
					</Button>
				</Tooltip>
			</Grid>
			<Grid item marginTop='auto' marginBottom='auto'></Grid>
		</Grid>
	);
}
