import { Reducer, Slice, Store, combineReducers, configureStore } from '@reduxjs/toolkit';
import { emptySplitApi } from 'Api';
import { api as systemApi } from 'Api/System';

const STATIC_APIS = [emptySplitApi, systemApi];
const STATIC_SLICES: Slice[] = [];

const STATIC_REDUCERS = {
	...STATIC_APIS.reduce((reducers, api) => ({ ...reducers, [api.reducerPath]: api.reducer }), {}),
	...STATIC_SLICES.reduce((reducers, slice) => ({ ...reducers, [slice.name]: slice.reducer }), {}),
};

const createReducer = (asyncReducers: { [key: string]: Reducer }) => {
	return combineReducers({ ...STATIC_REDUCERS, ...asyncReducers });
};

type AsyncStore = {
	asyncReducers?: { [key: string]: Reducer };
	injectReducer?: (key: string, asyncReducer: Reducer) => void;
};

export const store: Store & AsyncStore = configureStore({
	reducer: STATIC_REDUCERS,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(STATIC_APIS.map((api) => api.middleware)),
});

store.asyncReducers = {};

store.injectReducer = (key, asyncReducer) => {
	if (store.asyncReducers) {
		store.asyncReducers[key] = asyncReducer;
		store.replaceReducer(createReducer(store.asyncReducers));
	}
};

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const injectApiReduxStore = async (name: string) => {
	const slice = await import(`Api/${name}`).then((module) => module.slice);
	store.injectReducer?.(slice.name, slice.reducer);
};
