import { LOGIN_SERVER } from './constants';
import Cookies from 'js-cookie';

export function useLogout() {
	const logout = () => {
		Cookies.remove('ID_TOKEN');
		window.location.replace(LOGIN_SERVER);
	};

	return { logout };
}
