import { LOGIN_SERVER } from './constants';
import { useAccountQuery } from './api';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';

export function useAccount() {
	const [authenticated, setAuthenticated] = useState(false);
	const { data: getAccountResults } = useAccountQuery(undefined, { skip: !authenticated });
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const idToken = Cookies.get('ID_TOKEN');
		if (idToken) {
			setAuthenticated(true);
		} else {
			window.location.replace(LOGIN_SERVER);
		}
	}, []);

	useEffect(() => {
		if (getAccountResults?.message) {
			enqueueSnackbar(getAccountResults?.message, { variant: 'error' });
		}
	}, [getAccountResults?.message, enqueueSnackbar]);

	return { account: getAccountResults?.account };
}
