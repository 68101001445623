import { WALLET_TAG, api, useWalletQuery } from './api';
import { useAppDispatch } from 'AppProvider/hooks';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useSubscription } from 'react-stomp-hooks';

enum EventType {
	UPDATE_WALLET = 'UPDATE_WALLET',
}

export function useWallet() {
	const { data: getWalletResults } = useWalletQuery(undefined, { refetchOnMountOrArgChange: true });
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useAppDispatch();

	useSubscription(['/event/wallet', '/user/event/wallet'], (message) => {
		const event = JSON.parse(message.body);
		// TODO Code smell for use of switch cases
		switch ((event as Event).type) {
			case EventType.UPDATE_WALLET:
				dispatch(api.util.invalidateTags([WALLET_TAG]));
				break;
		}
	});

	useEffect(() => {
		if (getWalletResults?.message) {
			enqueueSnackbar(getWalletResults?.message, { variant: 'error' });
		}
	}, [getWalletResults, enqueueSnackbar]);

	return { wallet: getWalletResults?.wallet };
}
