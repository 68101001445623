import { Box, Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { ReactRouterBreadCrumbs } from 'Component';
import { useAccount } from 'Api/System';
import Header from './Header';

export function Root() {
	const { account } = useAccount();

	return (
		<Box height='100vh' width='100vw'>
			<Box width='100%' height='10%' justifyContent={'center'} alignContent='center'>
				<Grid container height='inherit' padding='1%'>
					<Grid item paddingLeft='1%' marginTop='auto' marginBottom='auto' xs>
						<ReactRouterBreadCrumbs />
					</Grid>
					<Grid item paddingRight='1%' marginTop='auto' marginBottom='auto' xs>
						{account && <Header />}
					</Grid>
				</Grid>
			</Box>
			<Box width='98%' height='77%' padding='1%' sx={{ overflow: 'auto' }}>
				{account && <Outlet />}
			</Box>
			<Box width='100%' height='5%'>
				<Grid container direction='column' justifyContent='center' alignItems='center' height='100%'>
					<Grid item>Footer</Grid>
				</Grid>
			</Box>
		</Box>
	);
}
