import { ReactNode } from 'react';
import { StompSessionProvider } from 'react-stomp-hooks';
import Cookies from 'js-cookie';

type StompSessionAuthenticationProviderProps = {
	children: ReactNode;
};

const BASE_WEBSOCKET_URL = 'https://demo.moyashi.link/ws?token=';

export default function AuthenticatedStompSessionProvider(props: StompSessionAuthenticationProviderProps) {
	const { children } = props;
	const idToken = Cookies.get('ID_TOKEN');
	const url = `${BASE_WEBSOCKET_URL}BEARER+${idToken}`;

	return <>{idToken ? <StompSessionProvider url={url}>{children}</StompSessionProvider> : children}</>;
}
