import { CheckoutDetails, useTopupMutation } from './api';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

export function useTopup() {
	const [topup, { data: topupResults, isLoading }] = useTopupMutation();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const showSnackbar = useRef(true);
	useEffect(() => {
		if (showSnackbar.current) {
			showSnackbar.current = false;
			if (pathname.endsWith('/success')) {
				enqueueSnackbar('Top up successful', { variant: 'success' });
			} else if (pathname.endsWith('/failure')) {
				enqueueSnackbar('Top up failed', { variant: 'error' });
			} else {
				return;
			}
			navigate('/top-up');
		}
	}, [pathname, navigate, enqueueSnackbar]);

	useEffect(() => {
		if (topupResults?.url) {
			window.location.replace(topupResults?.url);
		}
	}, [topupResults]);

	const topupWrapper = (checkoutDetails: CheckoutDetails) => {
		if (!isLoading) {
			topup(checkoutDetails);
		}
	};

	return { topup: topupWrapper, isLoading };
}
