import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

export const emptySplitApi = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: 'https://demo.moyashi.link/api/',
		mode: 'cors',
		credentials: 'include',
		redirect: 'error',
		prepareHeaders: (headers) => {
			const ID_TOKEN = Cookies.get('ID_TOKEN');
			if (ID_TOKEN) {
				headers.set('Authorization', `BEARER ${ID_TOKEN}`);
			}
			return headers;
		},
	}),
	endpoints: () => ({}),
});
