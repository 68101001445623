import './styles.css';
import { Backdrop, CircularProgress } from '@mui/material';
import { ROUTE_CONFIG } from './routes';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';

function App() {
	const router = createBrowserRouter(ROUTE_CONFIG);
	return (
		<Suspense
			fallback={
				<Backdrop open invisible>
					<CircularProgress />
				</Backdrop>
			}
		>
			<RouterProvider router={router} />
		</Suspense>
	);
}

export default App;
