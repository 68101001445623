import { Breadcrumbs, BreadcrumbsProps, Link, Typography } from '@mui/material';
import { useMatches, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

interface Crumb {
	label: string;
	href: string;
}

interface RouteHandle {
	crumb?: Crumb;
}

export function ReactRouterBreadCrumbs(props: BreadcrumbsProps) {
	const matches = useMatches();
	const navigate = useNavigate();

	const children = useMemo(() => {
		const crumbs = matches
			.map((match) => (match.handle as RouteHandle)?.crumb)
			.filter((crumb): crumb is Crumb => crumb !== undefined);

		if (crumbs.length > 1) {
			const children = crumbs.slice(0, crumbs.length - 1)?.map(({ label, href }) => {
				const onClick = (event: React.MouseEvent) => {
					event.preventDefault();
					navigate(href);
				};
				return (
					<Link key={label} underline='hover' color='inherit' href='#' onClick={onClick}>
						{label}
					</Link>
				);
			});

			const { label } = crumbs[crumbs.length - 1];
			children.push(
				<Typography key={label} color='text.primary'>
					{label}
				</Typography>,
			);

			return children;
		}
		return [];
	}, [matches, navigate]);

	return <Breadcrumbs {...props}>{children}</Breadcrumbs>;
}
