import { Chip } from '@mui/material';
import { MiniGameTicketIcon } from './MiniGameTicketIcon';
import { ReactNode } from 'react';

export interface MiniGameTicketChipProps {
	miniGameTickets: number | string | undefined | ReactNode;
}

export function MiniGameTicketChip({ miniGameTickets }: MiniGameTicketChipProps) {
	return (
		<Chip
			label={miniGameTickets}
			icon={<MiniGameTicketIcon color='inherit' sx={{ '&&': { marginLeft: '8px' } }} />}
			variant='outlined'
		/>
	);
}
