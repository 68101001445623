import { Chip } from '@mui/material';
import { ReactNode } from 'react';
import { ReceiptIcon } from './ReceiptIcon';

export interface ReceiptChipProps {
	receipts: number | string | undefined | ReactNode;
}

export function ReceiptChip({ receipts }: ReceiptChipProps) {
	return (
		<Chip
			label={receipts}
			icon={<ReceiptIcon color='inherit' sx={{ '&&': { marginLeft: '8px' } }} />}
			variant='outlined'
		/>
	);
}
